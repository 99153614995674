import React from "react"
import { PageProps } from "gatsby"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { List, ListItem, Meta, Image, ShareButtons } from "../components"
import { SEO } from "../components/Seo/Seo"
import { StaticImage } from 'gatsby-plugin-image'


const Index: React.FC<PageProps> = () => (
  <PageLayout>
    <Meta title="Home" />
    <Container>
      <div className="mt-1 flex flex-col-reverse lg:flex-row items-center lg:space-x-6"
          style={{margin: '8vw 0 0 0vw'}}>
        <Introduction size={{ "@initial": "default", "@xs": "large" }}>
          Hi there, 
          <br />
          I’m Matheus Toazza Tura
          <br />
          a backend developer
          <br />          
          for
          <PaypalImageWrapper>
            <StaticImage
              src="../content/images/paypal-logo-light.png"
              alt="PayPal"
              layout="constrained"
              width={95}
            />
          </PaypalImageWrapper>
          at
          <VenmoImageWrapper>
            <StaticImage
              src="../content/images/venmo-logo.png"
              alt="Venmo"
              layout="constrained"
              width={100}
            />
          </VenmoImageWrapper>
          <br />
          <br />
        </Introduction>
          <div className="relative h-full top-0 flex items-center">
            <div className="h-32 w-32 md:h-32 md:w-32 lg:h-32 lg:w-32 rounded-full overflow-hidden">
              <Image />
            </div>
          </div>
      </div>
      <div className="relative mt-1 flex flex-col-reverse lg:flex-row items-center lg:space-x-6">
        <ShareButtons />
      </div>
      <Lists>
        <ListContainer title="About Me">
          <ListItem
            subtitlePosition="above"
            subtitle="Living In"
            link="https://goo.gl/maps/QitC7mWmVEqhXW968"
          >
            Brazil
          </ListItem>
        </ListContainer>
      </Lists>
    </Container>
  </PageLayout>
)

const Container = styled("section", {
  length: 0
})

const Introduction = styled("h1", {
  color: "$slate12",
  fontFamily: "$display",
  fontWeight: 600,
  lineHeight: "$400",
  overflowWrap: "break-word",
  maxWidth: 640,
  length: 0,
  variants: {
    size: {
      default: {
        fontSize: "$xl",
      },
      large: {
        fontSize: "$2xl",
      },
    },
  },  
})

const Lists = styled("div", {
  marginTop: "$8",
  marginBottom: "$8",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  maxWidth: 640,
  length: 0
})

const ListContainer = styled(List, {
  margin: "$8 10px $8 0",
  minWidth: 150,
  maxWidth: 200,
  length: 0
})

const PaypalImageWrapper = styled("span", {
  display: "inline-block",
  verticalAlign: "middle",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
})

const VenmoImageWrapper = styled("span", {
  display: "inline-block",
  verticalAlign: "middle",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  marginTop: "0.68rem"
})

export default Index;

export const Head = () => (
  <SEO title="index" pathname="/"/>
)
